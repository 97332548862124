<template>
  <v-dialog v-model="dialog" max-width="800px" @click:outside="onClose">
    <template v-slot:activator="{ on }">
      <v-btn block :color="color" v-on="on" data-test="activate-button">
        {{ buttonText }} 
      </v-btn>
    </template>
    <v-card color="grey lighten-2" tile>
      <v-card-title>
        <v-toolbar color="primary" dark dense>
          <v-toolbar-title>{{ toolbarText }}</v-toolbar-title>
          <v-spacer />
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="onClose" v-on="on" data-test="close-button">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close this window</span>
          </v-tooltip>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row align="center">
            <v-col cols="12">
              <v-row dense class="mt-2">
                <v-col cols="12" md="8">
                  <v-text-field
                    dense
                    :label="labelText"
                    name="searchField"
                    type="text"
                    autocomplete="off"
                    autofocus
                    clearable
                    hide-details
                    v-model="searchString"
                    @click:clear="clearList"
                    @keypress.enter.stop="onSearch"
                    data-test="search-field"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn
                    block
                    :disabled="!searchString"
                    color="secondary"
                    @click="onSearch"
                    data-test="search-button"
                    >Search</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div v-if="arrayList && arrayList.length > 0" class="mt-5" data-test="div-list">
            <v-divider />
            <v-row dense v-for="(item, index) in arrayList" :key="index">
              <v-col cols="12">
                <v-btn
                  block
                  class="pa-2 mt-1 text-center"
                  @click="createRelation(item.id)"
                  :data-test="`item-button-${index}`"
                >
                  <span class="text-truncate" style="max-width:700px">{{
                    `${toolbarText} (${item.id}) - ${item.name}`
                  }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div v-else class="mt-5" data-test="div-nolist">
            <v-divider />
            <v-card color="grey lighten-2" flat>
              <v-card-text class="body-2 font-weight-bold text-center"
                >{{`No ${toolbarText.toLowerCase()} to display`}}</v-card-text
              >
            </v-card>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "CreateRelationModal",
  props: {
    toolbarText: {
      type: String,
      default: null,
    },
    labelText: {
      type: String,
      default: null,
    },
    arrayList: {
      type: Array,
      default: null,
    },
    company: {
      type: Object,
      default: null,
    },
    buttonText: {
      type: String,
      default: "Add User to Company",
    },
    color: {
      type: String,
      default: "secondary",
    },
  },
  data() {
    return {
      dialog: false,
      searchString: null,
    };
  },
  methods: {
    onClose() {
      this.$emit("onClearList");
      this.searchString = null;
      this.dialog = false;
    },
    onSearch() {
      this.$emit("searchCreateRelation", this.searchString)
    },
    createRelation(id) {
      this.$emit("onCreateRelation", { id, company: this.company });
      this.onClose();
    },
    clearList() {
      this.$emit("onClearList");
    },
  },
};
</script>
